import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[xOnlyNumberPattern]',
})
export class OnlyNumberPatternDirective {
  _regExp: RegExp;
  @Input() set regExp(input: RegExp | string) {
    this._regExp = new RegExp(input);
  }

  get regExp(): RegExp {
    return this._regExp;
  }

  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
  ];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.regExp) return;

    const { key } = event;

    if (this.specialKeys.includes(key)) {
      return;
    }

    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);

    if (isNaN(Number(next))) event.preventDefault();

    if (next && !String(next).match(this.regExp)) {
      event.preventDefault();
    }
  }
}
