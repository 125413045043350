import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[xTrimInput]',
})
export class TrimInputDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  @HostListener('paste', ['$event'])
  trimValue(event: Event) {
    let currentValue = this.el.nativeElement.value;
    const trimmedValue = currentValue.trim();

    if (currentValue !== trimmedValue) {
      this.el.nativeElement.value = trimmedValue;

      // Trigger input event to update the form control value
      const inputEvent = new Event('input', { bubbles: true });
      this.el.nativeElement.dispatchEvent(inputEvent);
    }
  }
}
